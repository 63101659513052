import React, { useState, useEffect } from "react";
import Logo from "../../assets/logo.png";
import "./Header.css";
import { Link } from "react-scroll";
import Bars from "../../assets/bars.png";

const Header = () => {
  const [menuOpened, setMenuOpened] = useState(false);
  const [mobile, setMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="header" id="header">
      <img src={Logo} alt="Logo" className="logo" />
      {menuOpened === false && mobile === true ? (
        <div className="bars-icon" onClick={() => setMenuOpened(true)}>
          <img src={Bars} alt="bars" className="bars-img" />
        </div>
      ) : (
        <ul className={`header-menu ${menuOpened ? "open" : ""}`}>
          <li>
            <Link onClick={() => setMenuOpened(false)} activeClass="active" to="header" spy={true} smooth={true}>
              Home
            </Link>
          </li>
          <li>
            <Link onClick={() => setMenuOpened(false)} to="programs" spy={true} smooth={true}>
              Programas
            </Link>
          </li>
          <li>
            <Link onClick={() => setMenuOpened(false)} to="news" spy={true} smooth={true}>
              Novidades
            </Link>
          </li>
          <li>
            <Link onClick={() => setMenuOpened(false)} to="testimonials" spy={true} smooth={true}>
              Assistencia
            </Link>
          </li>
          <li>
            <Link onClick={() => setMenuOpened(false)} to="contactos" spy={true} smooth={true}>
              Contactos
            </Link>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Header;
