import './App.css';
import Footer from './components/Footer/Footer';

import Header from './components/Header/Header';
import Join from './components/Join/Join';

import Programs from './components/Programs/Programs';
import Reasons from './components/Reasons/Reasons';
// import Location from './components/Location/location';
import Testimonials from './components/Testimonials/Testimonials';
import News from './components/News/News';

import Home from './components/Home/Home';
function App() {
  return (
    <div className="App">
      <Header/>
      <Home/>
      <Programs/>
      <Reasons/>
      <News/>
      <Testimonials/>
      <Join/>
      <Footer/>
     

    </div>
  );
}

export default App;
