import React from 'react';
import './Home.css';
import Wall from '../../assets/wall.jpg';

const Home = () => {
  return (
    <div className="home-container">
      <img src={Wall} alt="Background" className="background-image" />
      <div className="overlay">
      <div className="right-r">
        <div className="intro-overlay">
          <h3>Comece a sua <span style={{ color: 'yellow' }}> jornada </span> agora </h3>
        </div>
      </div>
      </div>
    </div>
  );
}

export default Home;
