import React, { useState } from 'react';
import './News.css'; // Make sure to adjust the path to your CSS file
import news1 from "../../assets/news1.jpg";
import news2 from "../../assets/news2.jpg";
import news3 from "../../assets/news3.jpg";
import news4 from "../../assets/news4.jpg";
import news5 from "../../assets/news5.jpg";
import news6 from "../../assets/news6.jpg";
import news7 from "../../assets/news7.PNG";


const newsItems = [
    { img: news1, title: "EQUIPAMENTOS TOPO DE GAMA", description: "Com um investimento constante na actualização do nosso equipamento, aqui tens as melhores máquinas para conquistares os teus objectivos com eficácia!." },
    { img: news2, title: "YOGA", description: "Participe de nossas aulas em grupo energéticas, que vão desde yoga e spinning até treinos HIIT." },
    { img: news3, title: "ZUMBA", description: "Com música vibrante, movimentos divertidos e instrutores experientes, a nossa aula é a forma perfeita de te exercitares enquanto te divertes." },
    { img: news4, title: "233 KIDS", description: "Com aulas especialmente projectadas para crianças, eles podem divertir-se, fazer amigos e colher os benefícios de um treino que desenvolve força, flexibilidade e habilidades motoras." },
    { img: news5, title: "CAPOEIRA ", description: "A capoeira é mais do que uma actividade física. É uma experiência que estimula a autoconfiança, disciplina e respeito." },
    { img: news6, title: "STRONG NATION ", description: "Na aula de Strong Nation, viverás uma experiência única de treino, onde a música une-se aos movimentos para transformar teu corpo e a mente." },
    { img: news7, title: "365 FITBAR ", description: " No 365 FITBAR temos à tua disposição, várias refeições saudáveis, cheias de sabor e ricas em nutrientes, que te dão aquele “boost” para superares o teu dia." }

];

const News = () => {
    const [currentIndex] = useState(0);
    return (
        <div className="plans-container" id='news'>
            <div className="programs-header" style={{ gap: "2rem" }}>
                <span className="stroke-text">Muitas</span>
                <span>Novidades</span>
            </div>
            <div className="latest-news-container">
                <div className="left-section">
                    {newsItems.slice(0, 4).map((item, index) => (
                        <div className="news-content" key={index}>
                            <div className="img-wrapper">
                                <img src={item.img} alt={`Gym ${index + 1}`} />
                            </div>
                            <div className='align_hp'>
                            <h2>{item.title}</h2>
                            <p>{item.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="right-section">
                    {newsItems.slice(4).map((item, index) => (
                        <div className="news-content" key={index}>
                            <div className="img-wrapper">
                                <img src={item.img} alt={`Gym ${index + 5}`} />
                            </div>
                            <div className='align_hp'>
                            <h2>{item.title}</h2>
                            <p>{item.description}</p>
                            </div>
                          
                        </div>
                        
                    ))}
                    <div>
                    
                    </div>
                </div>
               
            </div>
            
            
            <div className="mobile-news-container">
                
                <div className="news-content-wrapper" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                    {newsItems.map((item, index) => (
                        <div className="news-content" key={index}>
                            <div className="img-wrapper">
                                <img src={item.img} alt={`Gym ${index + 1}`} />
                            </div>

                            <h2>{item.title}</h2>
                            <p>{item.description}</p>
                        </div>
                        
                        
                    ))}
                 
                    
                </div>
               
                
            </div>
        </div>
    );
};

export default News;
