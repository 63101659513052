import image1 from "../assets/image1.png";
import image2 from "../assets/image2.png";
import image3 from "../assets/image3.png";
import c4 from "../assets/c4.PNG";
import c5 from "../assets/c5.PNG";
import c6 from "../assets/c6.PNG";

export const testimonialsData = [
  {
    image: image1,
    qualities: [
      "Emagrecimento com peso corporal",
      "Tabata",
      "Capoeira"
    ],
    name: 'Paulo Bob',
    status : 'Personal Trainer'
  },
  {
    image: image2,
    qualities: [
      "Emagrecimento",
      "Hipertrofia Muscular"
      
    ],
    name: 'Vino',
    status: 'Personal Trainer'
  },
  {
    image : image3,
    qualities: [
      "Emagrecimento",
      "Hipertrofia Muscular",
      "Tonificacao Musculat"
    ],
    name: 'Curtu',
    status: "Personal Trainer"
  },
  {
    image : c4,
    qualities: [
      "Treino especial- Diabete & Obesidade",
      "Emagrecimento",
      "Hipertrofia Muscular"
    ],
    name: 'Paulo de Deus',
    status: "Personal Trainer"
  },
  {
    image : c5,
    qualities: [
      "Preparamento fisico (para atletas)",
      "Tonificacao Musculat",
      "Aulas : Boxe, Capoeira, TRX, Aerobica"
    ],
    name: 'Acacio Zumbi',
    status: "Personal Trainer"
  },
  {
    image : c6,
    qualities: [
      "Emagrecimento",
      "Hipertrofia Muscular"
    ],
    name: 'Samllee',
    status: "Personal Trainer"
  }
];
