import React from 'react';
import './Footer.css';
import { FaFacebook, FaTwitter, FaInstagram, } from 'react-icons/fa';
import Logo from "../../assets/logo.png";

function Footer() {
  return (
    <footer className="footer" id='contactos'>
      <div className="footer-container">
        <div className="footer-column">
          <div className="footer-logo">
            <img src={Logo} alt="Logo" />
          </div>
        </div>
        <div className="footer-column">
          <div className="contact-info">
            <h3>Contact Us</h3>
            <p>Email: info@365fitness.co.mz</p>
            <p>Phone: +258 87 036 5365</p>
          </div>
        </div>
        <div className="footer-column">
          <div className="address">
            <h3>Address</h3>
            <p>Av. Belmiro Obadias Muianga n.120, Distriro Municipal Kampfumo</p>
            <p>Maputo, Mozambique</p>
          </div>
        </div>
        <div className="footer-column">
          <div className="social-links">
            <h3>Follow Us</h3>
            <div className="social-icons">
              <a href="https://web.facebook.com/people/365-Fitness/100064181947067/" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
              <a href="#"><FaTwitter /></a>
              <a href="https://www.instagram.com/365fitnessmoz/?hl=en" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
            </div>
          </div>
        </div>
        <div className="footer-column">
          <div className="opening-hours">
            <h3>Operating Hours</h3>
            <table>
              <tbody>
                <tr><td>Seg - Sex:</td><td>5:00 AM - 9:00 PM</td></tr>
                <tr><td>Sabado:</td><td>6:00 AM - 5:00 PM</td></tr>
                <tr><td>Domingo:</td><td>7:00 AM - 1:00 PM</td></tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>Copyright © 2024. Built by Yassir ALI.</p>
      </div>
    </footer>
  );
}

export default Footer;
