import React from "react";
import "./Testimonials.css";
import leftArrow from "../../assets/leftArrow.png";
import rightArrow from "../../assets/rightArrow.png";
import { testimonialsData } from "../../data/testimonialsData";
import { useState } from "react";
import { motion } from "framer-motion";
const Testimonials = () => {
  const [selected, setSelected] = useState(0);
  const tLength = testimonialsData.length;
  const transition = { type: "spring", duration: 1 };

  return (

    <div className="testimonials">
      <div className="left-t">
        <span>Profissionais Qualificados e Dedicados</span>
        <span>Nossos Treinadores</span>
       
        <motion.span
          key={selected}
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 100 }}
          transition={transition}
        > <span>
        <span style={{ color: "var(--orange)" ,"font-size":"1rem"}}>
          {testimonialsData[selected].name}
        </span>{" "}
        - {testimonialsData[selected].status}
        </span>{" "}
        </motion.span>
        <div className='outer'>
        <div className='a'>
        <motion.span
          key={selected}
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 100 }}
          transition={transition} >
          <ul><span className="textx">
          {testimonialsData[selected].qualities.map((quality, index) => (
            <li key={index}>{quality}</li>))
           
          } </span>
          </ul> 
          
        
        </motion.span>
        </div>
        <div className="right-t">
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            transition={{ ...transition, duration: 2 }}
            whileInView={{ opacity: 1, x: 0 }}
          ></motion.div>
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            transition={{ ...transition, duration: 2 }}
            whileInView={{ opacity: 1, x: 0 }}
          ></motion.div>
          
          <motion.img
            key={selected}
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={transition}
            src={testimonialsData[selected].image}
            alt=""
          />
          <div className="arrows">
            <img
              src={leftArrow}
              alt=""
              onClick={() => {
                selected === 0
                  ? setSelected(tLength - 1)
                  : setSelected((prev) => prev - 1);
              }}
            />{" "}
            <img
              src={rightArrow}
              alt=""
              onClick={() => {
                selected === tLength - 1
                  ? setSelected(0)
                  : setSelected((prev) => prev + 1);
              }}
            />
        </div>
      </div>
      </div>
      </div>
    </div>
  );
};

export default Testimonials;
