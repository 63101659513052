import React from "react";
import i1 from "../../assets/i1.jpeg";
import i2 from "../../assets/i2.PNG";
import i3 from "../../assets/i3.PNG";
import image4 from "../../assets/image4.png";
import partner1 from '../../assets/partner1.png';
import partner2 from '../../assets/partner2.png';
import partner3 from '../../assets/partner3.png';
import tick from "../../assets/tick.png";
import "./Reasons.css";
const Reasons = () => {
  return (
    <div className="Reasons" id='reasons'>
      <div className="left-r">
        <img src={i1} alt="" />
        <img src={i2} alt="" />
        <img src={i3} alt="" />
        <img src={image4} alt="" />
      </div>
      <div className="right-r">
        <span>A MELHOR ACADEMIA</span>
        <div>
          <span className="stroke-text">POR QUE </span>
          <span>NOS?</span>
        </div>
        <div className="details-r">
          <div>
            <img src={tick} alt="" />
            <span>MAIS DE 15 TREINADORES ESPECIALIZADOS</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>EQUIPAMENTOS DE ALTA QUALIDADE</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>SEGURO E CONFIÁVEL</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>PARCEIROS DE CONFIANÇA</span>
          </div>
        </div>
        <span style={{color: "var(--gray)", fontWeight: "normal"}}>NOSSOS PARCEIROS</span>
        <div className="partners">
              <img src={partner1} alt="" />
              <img src={partner2} alt="" />
              <img src={partner3} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Reasons;
