import React from "react";
import "./Programs.css";
import { programsData } from "../../data/programsData";
import { motion } from "framer-motion";
const Programs = () => {
  return (
    
    <div className="plans-container" id='programs'>
      <div className="programs-header" style={{ gap: "2rem" }}>
        <span className="stroke-text">Explore</span>
        <span>vários</span>
        <span className="stroke-text">Programas</span>
      </div>
      <div className="program-categories">
          {programsData.map((program) => (
            <motion.div
              className="category"
            >
                {program.image}
                <span>{program.heading}</span>
                <span>{program.details}</span>
               
              <div className="hover-bar"></div>
            </motion.div>
          ))}
        </div>


    </div>
    
  );
};

export default Programs;
